import React from 'react';
import ReactDOM from 'react-dom';
import singleSpaReact from 'single-spa-react';
import Root from './root.component';

const lifecycles = singleSpaReact({
  React,
  ReactDOM,
  rootComponent: Root,
  renderType: 'render',
  errorBoundary(err, info, props) {
    // Customize the root error boundary for your microfrontend here.
    console.group('Error Boundary');
    console.error('Error Raw', err);
    console.error('Error Info', info);
    console.error('Error Props', props);
    console.groupEnd();
    return null;
  },
});

export const { bootstrap, mount, unmount } = lifecycles;

if (module.hot) {
  module.hot.accept();
}
