import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { queryClient } from './shared/api/queryClient';
import { FloraProvider } from '@grupoboticario/flora-react';
import { gbInstitucionalTheme } from '@grupoboticario/flora';

import { App } from './App';

export default function Root({ user }) {
  return (
    <QueryClientProvider client={queryClient}>
      <FloraProvider theme={gbInstitucionalTheme} resetCSS={false}>
        <App user={user} />
      </FloraProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}
