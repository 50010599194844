import {
  Flex,
  Accordion,
  AccordionItem,
  AccordionButton,
  FloraTypography,
  AccordionPanel,
  FloraButton,
} from '@grupoboticario/flora-react';
import { faqItems } from './faqItems';
import { useState } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@grupoboticario/flora-react-icons';
import { pushEventToDataLayer } from '@shared/analytics/pushEventToDataLayer';

export function Faq() {
  const [showAll, setShowAll] = useState(false);

  const visibleItems = showAll ? faqItems : faqItems.slice(0, 4);

  return (
    <Flex
      direction="column"
      css={{ flex: '1 1 auto', backgroundColor: 'white', padding: '$6', borderRadius: '$medium' }}
    >
      <FloraTypography fontSize="subtitle" fontWeight="bold" css={{ fontSize: '$subtitleMobile' }}>
        Perguntas frequentes
      </FloraTypography>
      <Accordion
        collapsible
        defaultValue="item-one"
        type="single"
        css={{
          listStyle: 'none',
          '& li': { listStyle: 'none' },
          '& h6': { margin: 0 },
          margin: '0',
          padding: 0,
        }}
      >
        {visibleItems.map(item => (
          <AccordionItem
            key={item.id}
            value={item.id}
            css={{ '& > li': { listStyle: 'none' } }}
            onClick={() => {
              pushEventToDataLayer({
                eventParameter: `click:faq:${item.id}`,
              });
            }}
          >
            <AccordionButton>
              <FloraTypography as="h6" fontSize="bodyLargeStandard" fontWeight="medium">
                {item.question}
              </FloraTypography>
            </AccordionButton>
            <AccordionPanel
              css={{
                '& .listinha li': { listStyleType: 'disc !important', listStylePosition: 'outside !important' },
              }}
            >
              {item.answer}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>

      {faqItems.length > 4 && (
        <Flex css={{ marginTop: '$4' }}>
          <FloraButton
            styleSemantic="neutral"
            hierarchy="tertiary"
            has="iconRight"
            icon={!showAll ? <ChevronDownIcon /> : <ChevronUpIcon />}
            onClick={() => {
              setShowAll(!showAll);
              pushEventToDataLayer({
                eventParameter: `click:faq:${showAll ? 'mostrar-menos' : 'mostrar-mais'}`,
              });
            }}
          >
            {showAll ? 'Mostrar menos' : 'Mostrar mais'}
          </FloraButton>
        </Flex>
      )}
    </Flex>
  );
}
