import { Card, Image } from '@grupoboticario/flora-react';

import CoverFaq from '../../../assets/images/capa-faq.jpg';
import CoverPeopleManagementManual from '../../../assets/images/capa-manual-menu-gestao-de-pessoas.jpg';
import CoverNavigationGuide from '../../../assets/images/capa-manual-navegacao-vdi.jpg';

import faqAdminPdf from '../../../assets/pdfs/FAQ-lideres.pdf';
import peopleManagementManualPdf from '../../../assets/pdfs/manual-menu-gestao-de-pessoas.pdf';
import navigationGuidePdf from '../../../assets/pdfs/manual-navegacao-vdi-lideres.pdf';
import { pushEventToDataLayer } from '@shared/analytics/pushEventToDataLayer';

export function DownloadableContent() {
  return (
    <>
      <Card padded={false}>
        <a
          href={navigationGuidePdf}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            pushEventToDataLayer({
              eventParameter: 'click:pdf-guia-navegacao',
            });
          }}
        >
          <Image src={CoverNavigationGuide} alt="Guia de navegação da VDI" css={{ borderRadius: '$medium' }} />
        </a>
      </Card>

      <Card padded={false}>
        <a
          href={peopleManagementManualPdf}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            pushEventToDataLayer({
              eventParameter: 'click:pdf-manual-gestao-pessoas',
            });
          }}
        >
          <Image
            src={CoverPeopleManagementManual}
            alt="Manual de gestão de pessoas"
            css={{ borderRadius: '$medium' }}
          />
        </a>
      </Card>

      <Card padded={false}>
        <a
          href={faqAdminPdf}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            pushEventToDataLayer({
              eventParameter: 'click:pdf-faq-lideres',
            });
          }}
        >
          <Image src={CoverFaq} alt="FAQ para líderes" css={{ borderRadius: '$medium' }} />
        </a>
      </Card>
    </>
  );
}
