import { Flex, FloraTypography, FloraButton } from '@grupoboticario/flora-react';
import { ArrowRightIcon } from '@grupoboticario/flora-react-icons';
import { pushEventToDataLayer } from '@shared/analytics/pushEventToDataLayer';
import { hourToGreeting } from '@shared/formatters/hourToGreeting';
import { type UserData } from '@vdi/auth-service';

type UserProfile = {
  name: string;
  id: string;
};

type HeaderProps = Pick<UserData, 'name' | 'geraId'> & {
  userProfiles: UserProfile[];
  isLoadingUserProfiles: boolean;
};

export function Header({ name, geraId, userProfiles, isLoadingUserProfiles }: HeaderProps) {
  const greeting = hourToGreeting();

  return (
    <Flex direction="row" align="center" justify="space-between">
      <Flex direction="column">
        <FloraTypography as="h1" fontWeight="bold" css={{ fontSize: '$subtitleDesk', lineHeight: '32px', margin: 0 }}>
          {greeting}, <span style={{ color: 'rgba(38, 79, 236, 1)' }}>{name.split(' ')[0]}</span>
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard" css={{ margin: '$2 0 0 0' }}>
          Seu perfil: <strong>{isLoadingUserProfiles ? '…' : userProfiles?.[0]?.name}</strong>
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard" css={{ margin: 0 }}>
          Seu código VD+: <strong>{geraId}</strong>
        </FloraTypography>
      </Flex>
      <Flex direction="row" gapX="$6">
        <FloraButton
          hierarchy="primary"
          styleSemantic="neutral"
          size="large"
          has="iconRight"
          icon={<ArrowRightIcon />}
          as="a"
          href="/ativacao/segmentacao"
          onClick={() => {
            pushEventToDataLayer({
              eventParameter: 'click:acesso-rapido-ativacao-segmentacao',
            });
          }}
        >
          Fazer segmentação
        </FloraButton>
        <FloraButton
          hierarchy="primary"
          styleSemantic="neutral"
          size="large"
          has="iconRight"
          icon={<ArrowRightIcon />}
          as="a"
          href="/ativacao/atendimento"
          onClick={() => {
            pushEventToDataLayer({
              eventParameter: 'click:acesso-rapido-ativacao-atendimento',
            });
          }}
        >
          Iniciar atendimento
        </FloraButton>
        <FloraButton
          hierarchy="primary"
          styleSemantic="neutral"
          size="large"
          has="iconRight"
          icon={<ArrowRightIcon />}
          as="a"
          href="/dashboards/visao-geral"
          onClick={() => {
            pushEventToDataLayer({
              eventParameter: 'click:acesso-rapido-dashboards-visao-geral',
            });
          }}
        >
          Acompanhar resultados
        </FloraButton>
      </Flex>
    </Flex>
  );
}
