import { Container } from '@grupoboticario/flora-react';
import { Header } from './components/Header';
import { InsideVDI } from './components/InsideVDI';
import { UserHelp } from './components/UserHelp';
import { useQuery } from '@tanstack/react-query';
import { authService, UserData } from '@vdi/auth-service';
import { pushEventToDataLayer } from '@shared/analytics/pushEventToDataLayer';

export function App({ user }: { user: UserData }) {
  const {
    data: userProfiles,
    isLoading: isLoadingUserProfiles,
    isSuccess,
  } = useQuery({
    queryKey: ['user', 'profile'],
    queryFn: async () => {
      const { token } = await authService.getTokenOrRedirect();
      const resp = await fetch(`${process.env.API_BASE_URL}/profiles`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      if (!resp.ok) {
        throw new Error('Network response was not ok');
      }

      return resp.json();
    },
  });

  if (isSuccess) {
    pushEventToDataLayer({
      eventParameter: 'pageview:home-page',
    });
  }

  return (
    <Container size="large" css={{ paddingBlock: '$10' }}>
      <Header
        name={user.name}
        geraId={user.geraId}
        userProfiles={userProfiles}
        isLoadingUserProfiles={isLoadingUserProfiles}
      />
      <InsideVDI userProfiles={userProfiles} />
      <UserHelp userProfiles={userProfiles} />
    </Container>
  );
}
