import { FloraTypography } from '@grupoboticario/flora-react';

export const faqItems = [
  {
    id: 'o-que-posso-fazer-na-vdi',
    question: 'O que posso fazer na VDI?',
    answer: (
      <ul className="listinha">
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Gerenciar a base de Revendedoras:</strong> visualizar o toda a sua base de revendedoras, e realizar
            filtros personalizados de acordo com a sua estratégia de ativação;
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Visualizar informações detalhadas de cada Revendedora:</strong> acessar o perfil de cada Revendedora
            e visualizar, de forma consolidada, informações valiosas para a ativação comercial, como o histórico de
            compras e fidelidade, crédito, promoções exclusivas, uso das ferramentas digitais e muitos outros!
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Registrar acionamentos:</strong> informar suas Revendedoras sobre promoções, campanhas, eventos,
            cobranças, etc. de forma rápida e eficiente.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Registrar atendimentos:</strong> registrar as interações com suas Revendedoras, como contato por
            telefone, WhatsApp ou mensagens, identificando o motivo e o submotivo do atendimento.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Visualizar indicadores de relacionamento:</strong> acompanhar o desempenho da sua equipe em relação
            a acionamentos, atendimentos e problemas de contato.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Acessar dados de negócio:</strong> acompanhar a performance da sua equipe em relação a vendas e
            receitas, perfil de base e atividade, podendo filtrar por diversos critérios como período, marca, canal de
            compra, etc.
          </FloraTypography>
        </li>
      </ul>
    ),
  },
  {
    id: 'quais-os-recursos-mais-importantes-da-vdi-para-o-meu-trabalho',
    question: 'Quais os recursos mais importantes da VDI para o meu trabalho?',
    answer: (
      <ul className="listinha">
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Segmentação de Base:</strong> permite visualizar a base de Revendedoras de acordo com o seu setor,
            filtrar por diversos critérios como nível de inatividade, ciclo atual, status de bloqueio, etc. e realizar
            ações de ativação, como acionamentos, atendimento, etc.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Registro de acionamentos:</strong> permite registrar o envio massificado de mensagens para suas
            Revendedoras, informando sobre promoções, campanhas, eventos, cobranças, etc.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Registro de atendimentos:</strong> permite registrar as interações com suas Revendedoras, como
            contato por telefone, WhatsApp ou mensagens, identificando o motivo e o submotivo do atendimento.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Indicadores de Relacionamento:</strong> permite acompanhar o desempenho da sua equipe em relação a
            acionamentos, atendimentos e problemas de contato, além de visualizar a performance da sua equipe em relação
            a vendas e receitas.
          </FloraTypography>
        </li>
        <li style={{ marginTop: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallShort">
            <strong>Visualização de resultados:</strong> acompanhe de forma prática e consolidada os principais
            indicadores do seu negócio, para formular estratégias e entender pontos de ação.
          </FloraTypography>
        </li>
      </ul>
    ),
  },
  {
    id: 'onde-encontrar-ajuda-em-caso-de-duvidas-ou-problemas-com-a-vdi',
    question: 'Onde encontrar ajuda em caso de dúvidas ou problemas com a VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        O time do CSF poderá te ajudar pelo telefone <strong>(41) 3375-1515</strong> ou chat disponível na Extranet.
      </FloraTypography>
    ),
  },
  {
    id: 'perderei-meu-acesso-ao-vd-mais',
    question: 'Perderei meu acesso ao VD+?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        As funcionalidades do VD+ que estão relacionadas ao trabalho da Força de Vendas serão desligadas gradualmente à
        medida que forem implementadas na VDI. Funcionalidades de logística, faturamento, etc, continuam na VD+ de
        acordo com o permissionamento de cada usuário. Todos esses processos ocorrerão após comunicação prévia.
      </FloraTypography>
    ),
  },
  {
    id: 'o-que-acontecera-com-o-app-da-super-e-o-superplano',
    question: 'O que acontecerá com o App da Super e o Superplano?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Essas soluções serão desidratadas à medida que a VDI esteja com todas as informações e dados necessários para a
        operação. Por enquanto, ninguém perderá nenhum acesso às essas ferramentas, o desligamento destas será
        comunicado com antecedência.
      </FloraTypography>
    ),
  },
  {
    id: 'como-funciona-a-consulta-de-estoque-e-itens-similares-na-vdi',
    question: 'Como funciona a consulta de estoque e itens similares na VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Agora é possível consultar o estoque e produtos similares direto pela VDI! Em{' '}
        <strong>Ativação &gt; Atendimento</strong>, busque o Revendedor, clique para iniciar um atendimento e depois em{' '}
        <strong>Consultar similares</strong>, que estará ao lado do botão de finalizar atendimento. Para recomendar
        produtos similares, o algoritmo leva em consideração uma série de atributos como marca, categoria, linha,
        caminho olfativo, etc. Depois ele confere se temos esse produto similar em estoque e sugere na tela. Para mais
        informações, consulte o Manual de Navegação disponível na Trilha “Voando mais alto com a VDI” na Unibê.
      </FloraTypography>
    ),
  },
  {
    id: 'qual-a-diferenca-pratica-entre-acionamento-e-atendimento',
    question: 'Qual a diferença prática entre Acionamento e Atendimento?',
    answer: (
      <>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          <strong>Acionamentos são ações realizadas para acionar uma ou mais REs</strong>. É o que chamamos de ativo.{' '}
          <strong>Já os Atendimentos são as trocas de mensagem</strong>, seja quando a RE retorna um acionamento
          anterior ou fala espontaneamente com você.{' '}
          <strong>
            Por enquanto,{' '}
            <u>problemas de contato que vieram de algum acionamento também devem ser registrados como atendimento</u>
          </strong>
          .
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Na prática:
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          <strong>
            Você quer avisar a todas as REs que faltam R$ 200 para mudar de faixa de Fidelidade. Agora é o momento de
            registrar o <u>Acionamento:</u>
          </strong>
        </FloraTypography>
        <ul className="listinha">
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              No menu Ativação, clique em SEGMENTAÇÃO DE BASE
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Em FILTROS, vá até Fidelidade e, no campo “valor para subir de faixa” digite 200, clique em “Aplicar
              Filtros”
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              No topo da lista, selecione todas as REs e clique no botão “Copiar itens selecionados”
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Ao abrir a janela clique em REGISTRAR ACIONAMENTO. Nesse exemplo, como estamos enviando sobre o tema
              Fidelidade vamos escolher o tipo “Campanha” e registre!
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Quando você clicou para copiar, os números das REs filtradas foram copiados, agora vá até a ferramenta de
              comunicação que utiliza e envie a mensagem.
            </FloraTypography>
          </li>
        </ul>
        <FloraTypography as="p" fontSize="bodySmallShort">
          <strong>
            Você enviou mensagem no acionamento para 10 REs, onde 2 vão fazer pedido, 1 irá no ER, 1 te bloqueou e 6 não
            responderam. Veja como registrar:
          </strong>
        </FloraTypography>
        <ul className="listinha">
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              No menu Ativação, clique em ATENDIMENTO;
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Busque pelo código e:
            </FloraTypography>
            <ul className="listinha">
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  Para as <u>REs que realizaram os pedidos</u>, após finalizar o pedido, feche o atendimento e registre
                  como Revendedor entrou em contato &gt; motivo Venda &gt; escolha o submotivo e finalize
                </FloraTypography>
              </li>
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  Para a <u>RE que vai no ER</u>, finalize o atendimento registrando como Revendedor entrou em contato
                  &gt; motivo Promessa de compra &gt; escolha o submotivo e finalize
                </FloraTypography>
              </li>
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  Para a <u>RE que te bloqueou</u> registre como Eu entrei em contato &gt; Revendedor não respondeu &gt;
                  motivo Número bloqueado
                </FloraTypography>
              </li>
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  <u>Para as 6 que não responderam</u> você não deve fazer nenhum tipo de registro.
                </FloraTypography>
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'de-maneira-resumida-o-que-devo-fazer-em-cada-tipo-de-contato',
    question: 'De maneira resumida, o que devo fazer em cada tipo de contato?',
    answer: (
      <>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Sempre que <u>VOCÊ entrar em contato</u> com um ou mais Revendedores, deve fazer o{' '}
          <u>registro do Acionamento</u> em “Segmentação de Base”. Se: &gt; Revendedor respondeu seu acionamento:
          Registre atendimento como “RE entrou em contato” usando os motivos e submotivos disponíveis &gt; Revendedor
          não respondeu acionamento: Não registre nada além do acionamento &gt; Identifiquei que houve algum problema
          com o envio da mensagem (número bloqueado, número errado): registre o atendimento como “problema de contato”.
        </FloraTypography>
        <ul className="listinha">
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              <strong>Revendedor respondeu seu acionamento:</strong> Registre atendimento como “RE entrou em contato”
              usando os motivos e submotivos disponíveis
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              <strong>Revendedor não respondeu acionamento:</strong> Não registre nada além do acionamento
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              <strong>
                Identifiquei que houve algum problema com o envio da mensagem (número bloqueado, número errado):
              </strong>{' '}
              registre o atendimento como “problema de contato”.
            </FloraTypography>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'para-que-serve-o-campo-informacoes-adicionais-na-vdi',
    question: 'Para que serve o campo “Informações Adicionais” na VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        O campo “Informações Adicionais” disponível no perfil do Revendedor pode ser utilizado para{' '}
        <strong>adicionar preferências ou detalhes que gostaria de lembrar da RE.</strong> Por exemplo: “Prefere contato
        por ligação” ou “Possui 2 lojas” ou “Vende em X lugar” ou “Seus clientes tem o perfil X”. São{' '}
        <strong>informações perenes</strong>, ou seja, sem data de validade e não devem ser relacionadas a um contato
        específico e sim ao perfil e preferências do RE de modo geral. Para registrar informações relacionadas a um
        contato específico, você deve utilizar o campo “Observações” no Registro de Atendimento após a interação.
      </FloraTypography>
    ),
  },
  {
    id: 'por-qual-motivo-devo-registrar-todos-os-contatos-que-faco-com-a-minha-base-qual-a-importancia-disso',
    question: 'Por qual motivo devo registrar todos os contatos que faço com a minha base? Qual a importância disso?',
    answer: (
      <>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          O registro de Atendimento é fundamental para a{' '}
          <strong>otimização da ferramenta e para melhorar a performance e eficiência dos atendimentos.</strong> A
          partir dos motivos tabulados nós entregamos diversas análises em Resultados &gt; Relacionamento.{' '}
          <strong>Sem os registros, não é possível acompanhar os indicadores de forma assertiva</strong>. Para que essas
          análises sejam precisas, os Acionamentos também devem ser registrados em Ativação &gt; Segmentação de Base.
          Dessa forma, tanto Supervisora como a Liderança têm uma{' '}
          <strong>visibilidade de conversão das suas bases</strong>: das pessoas que você acionou, quantas retornaram,
          quais foram os motivos, quantas fizeram pedidos, etc.
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          O registro dos Acionamentos e Atendimentos é fundamental para que você consiga{' '}
          <strong>acompanhar e fortalecer o relacionamento com a sua base de revendedores</strong>. Com eles, você
          consegue deixar registrado os contatos que teve com cada revendedor, podendo desenhar estratégias de ativação
          cada vez mais personalizadas. Com o registro de acionamento você acompanha todas as mensagens e contatos que
          procurou iniciar, podendo{' '}
          <strong>analisar com quanto da sua base você já entrou em contato e quais temas já comunicou</strong>. Com o
          registro de atendimento você acompanha o detalhe da conversa com os revendedores que te responderam ou te
          procuraram ativamente, incluindo o motivo daquela conversa e anotações importantes para um próximo contato.
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Assim, é <strong>essencial realizar as duas etapas</strong>, para que tanto Supervisor quanto Liderança tenham
          visibilidade da conversão das suas bases: das pessoas que você acionou, quantas retornaram, quais foram os
          motivos, quantas fizeram pedidos, etc.
        </FloraTypography>
      </>
    ),
  },
  {
    id: 'como-visualizar-na-vdi-em-qual-ciclo-os-revendedores-estao-durante-a-captacao-estendida',
    question: 'Como visualizar na VDI em qual ciclo os Revendedores estão durante a captação estendida?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Quando estamos em captação estendida, uma mensagem azul aparecerá no topo da lista na{' '}
        <strong>aba “Segmentação de Base”</strong>e a coluna “Ciclo Atual” fica disponível. A partir dela, é possível{' '}
        <strong>filtrar REs pelo ciclo atual</strong>.
      </FloraTypography>
    ),
  },
  {
    id: 'que-tipo-de-ofertas-aparecem-na-secao-promocoes-da-vdi',
    question: 'Que tipo de ofertas aparecem na seção “Promoções” da VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        As promoções disponíveis para consulta no perfil do Revendedor na VDI são as do <strong>Alerta Multi</strong>{' '}
        (antigo Ânfora Dourada), exclusivas para o cadastro daquele RE. Para as demais ofertas do ciclo, consulte os
        materiais do ciclo.
      </FloraTypography>
    ),
  },
  {
    id: 'quais-indicadores-e-possivel-acompanhar-na-vdi',
    question: 'Quais indicadores é possível acompanhar na VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        É possível acompanhar, através do Menu Resultados, indicadores de relacionamento, atividade, perfil de base,
        receita e uma visão geral, de maneira bem completa e intuitiva.
      </FloraTypography>
    ),
  },
];
