import { Flex, FloraTypography, FloraButton, FloraTextarea, Box } from '@grupoboticario/flora-react';
import { ChatBubbleIcon, PhoneCallIcon } from '@grupoboticario/flora-react-icons';
import { ProfileContentSwitcher } from '@shared/components/ProfileContentSwitcher';
import { UnibeBanner as AdminUnibeBanner } from './AdminProfile/UnibeBanner';
import { UnibeBanner as AttendantUnibeBanner } from './AttendantProfile/UnibeBanner';
import { UserProfile } from '@shared/types/userProfiles';
import { Faq as AdminFaq } from './AdminProfile/Faq';
import { Faq as AttendantFaq } from './AttendantProfile/Faq';
import { useMutation } from '@tanstack/react-query';
import { authService } from '@vdi/auth-service';
import { useRef, useState } from 'react';
import { pushEventToDataLayer } from '@shared/analytics/pushEventToDataLayer';

type UserHelpProps = {
  userProfiles: UserProfile[];
};

export function UserHelp({ userProfiles }: UserHelpProps) {
  const formRef = useRef<HTMLFormElement>(null);
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const [formSentCount, setFormSentCount] = useState(0);
  const [messageSentHelperText, setMessageSentHelperText] = useState('');

  const { mutate, isPending } = useMutation({
    mutationKey: ['feedback'],
    mutationFn: async ({ message, profiles }: { message: string; profiles: string[] }) => {
      const { token } = await authService.getTokenOrRedirect();
      const resp = await fetch(`${process.env.API_BASE_URL}/feedback`, {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${token}`,
          'content-type': 'application/json',
        },
        body: JSON.stringify({ message, profiles }),
      });

      if (!resp.ok) {
        throw new Error('Network response was not ok');
      }

      return resp.json();
    },
    onSuccess: () => {
      pushEventToDataLayer({
        eventParameter: 'click:enviar-sugestao-de-melhoria',
      });

      setMessageSentHelperText('Mensagem enviada com sucesso');
      setFormSentCount(formSentCount + 1);
      if (textareaRef.current) {
        textareaRef.current.value = '';
      }

      setTimeout(() => {
        setMessageSentHelperText('');
      }, 5000);
    },
  });

  function handleOnSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    const formData = new FormData(formRef.current as HTMLFormElement);
    const message = formData.get('message') as string;

    if (!message) {
      return;
    }

    mutate({ message, profiles: userProfiles.map(profile => profile.name) });
  }

  return (
    <Flex direction="row" gap="$8" css={{ marginTop: '$12' }}>
      <Flex direction="column" gapY="$8" css={{ flex: '2 0 0' }}>
        <ProfileContentSwitcher
          userProfiles={userProfiles}
          profileToMatch={['Administrador Franquias Default', 'Coordenador Franquias Default']}
          contentForMatchedProfile={<AdminUnibeBanner />}
          contentFormNonMatchedProfile={<AttendantUnibeBanner />}
        />

        <ProfileContentSwitcher
          userProfiles={userProfiles}
          profileToMatch={['Administrador Franquias Default', 'Coordenador Franquias Default']}
          contentForMatchedProfile={<AdminFaq />}
          contentFormNonMatchedProfile={<AttendantFaq />}
        />
      </Flex>
      <Flex
        direction="column"
        css={{ flex: '1 0 0', backgroundColor: 'white', padding: '$9', borderRadius: '$medium' }}
      >
        <FloraTypography as="h5" fontSize="bodyLargeShort" fontWeight="bold" css={{ marginBottom: '0' }}>
          Precisa de Ajuda? Encontrou algum erro?
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard" css={{ marginTop: '$4' }}>
          Abra um chamado se você encontrou um problema ou erro na VDI através dos canais de atendimento abaixo.
        </FloraTypography>
        <Flex direction="row">
          <FloraTypography
            as="p"
            fontSize="bodySmallStandard"
            fontWeight="regular"
            css={{ flex: '1 1 40%', margin: 0 }}
          >
            <PhoneCallIcon size="14px" css={{ color: '$actionableDefault' }} /> Telefone:
            <br />
            <FloraTypography as="span" fontSize="bodyLargeShort" fontWeight="bold">
              (41) 3375-1515
            </FloraTypography>
          </FloraTypography>
          <FloraTypography
            as="p"
            fontSize="bodySmallStandard"
            fontWeight="regular"
            css={{
              color: '$nonInteractivePredominant',
              flex: '1 1 60%',
              margin: 0,
              paddingLeft: '$4',
              borderLeft: '2px solid $actionableDefault',
            }}
          >
            <strong style={{ fontWeight: '600' }}>Horário de Atendimento:</strong> das 8h às 20h (Segunda à Sábado)
          </FloraTypography>
        </Flex>
        <FloraTypography as="p" fontSize="bodySmallStandard" css={{ marginTop: '$6' }}>
          Comece uma conversa no chat para obter ajuda
        </FloraTypography>
        <Flex direction="row">
          <Box css={{ flex: '1 1 40%' }}>
            <FloraButton
              size="small"
              as="a"
              href="https://extranet.grupoboticario.com.br/mfe/gb-resolve"
              target="_blank"
              has="iconRight"
              icon={<ChatBubbleIcon />}
              onClick={() => {
                pushEventToDataLayer({
                  eventParameter: `click:abrir-chat`,
                });
              }}
            >
              Abrir chat
            </FloraButton>
          </Box>
          <FloraTypography
            as="p"
            fontSize="bodySmallStandard"
            fontWeight="regular"
            css={{
              color: '$nonInteractivePredominant',
              flex: '1 1 60%',
              margin: 0,
              paddingLeft: '$4',
              borderLeft: '2px solid $actionableDefault',
            }}
          >
            <strong style={{ fontWeight: '600' }}>Chat:</strong> das 8:30h às 19h (Segunda à Sábado)
          </FloraTypography>
        </Flex>
        <FloraTypography as="h6" fontSize="bodyLargeStandard" fontWeight="bold" css={{ marginTop: '$8' }}>
          Sugestão de melhorias
        </FloraTypography>
        <form
          ref={formRef}
          key={formSentCount}
          style={{ display: 'flex', flexDirection: 'column' }}
          onSubmit={handleOnSubmit}
        >
          <FloraTextarea
            ref={textareaRef}
            id="message"
            name="message"
            label="Envie uma sugestão ou crítica"
            maxLength={180}
            helperText={messageSentHelperText}
            status={messageSentHelperText ? 'success' : undefined}
            required
          />
          <FloraButton
            type="submit"
            size="small"
            css={{ margin: '$4 0 0 auto' }}
            disabled={isPending}
            isLoading={isPending}
          >
            Enviar
          </FloraButton>
        </form>
      </Flex>
    </Flex>
  );
}
