import { type UserProfile } from '@shared/types/userProfiles';

type ProfileToMatch =
  | 'Administrador Franquias Default'
  | 'Coordenador Franquias Default'
  | 'Atendente Franquias Default'
  | 'Estruturante - Admin';

type ProfileContentSwitcherProps = {
  userProfiles: UserProfile[];
  profileToMatch: ProfileToMatch[];
  contentForMatchedProfile: React.ReactNode;
  contentFormNonMatchedProfile: React.ReactNode;
};

export function ProfileContentSwitcher({
  userProfiles,
  profileToMatch,
  contentForMatchedProfile,
  contentFormNonMatchedProfile,
}: ProfileContentSwitcherProps) {
  if (!userProfiles) return null;

  const matchProfile = userProfiles?.some(profileItem => profileToMatch.includes(profileItem.name as ProfileToMatch));

  if (matchProfile) {
    return <>{contentForMatchedProfile}</>;
  }

  return <>{contentFormNonMatchedProfile}</>;
}
