import { FloraTypography } from '@grupoboticario/flora-react';

export const faqItems = [
  {
    id: 'qual-a-diferenca-entre-os-indicadores-disponiveis-na-vdi-e-os-indicadores-disponiveis-na-gi',
    question: 'Qual a diferença entre os indicadores disponíveis na VDI e os indicadores disponíveis na GI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Na VDI é possível acompanhar relatórios mais gerenciais do Canal VD (antes disponíveis no SuperPlano) e
        indicadores de relacionamento, uma novidade exclusiva da VD Interativa. Já na GI, temos relatórios mais
        executivos com indicadores da franquia.
      </FloraTypography>
    ),
  },
  {
    id: 'como-fazer-a-gestao-de-usuarios-e-equipes-na-vdi',
    question: 'Como fazer a gestão de usuários e equipes na VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Através do menu ‘Gestão de Pessoas’, dedicado unicamente a acessos admin concedidos apenas líderes e pessoas
        designadas na operação para fazer a gestão de equipes e de usuários dentro da VDI. Pessoas que possuem acesso
        admin podem ativar ou desativar acessos à VDI de pessoas da equipe e organizar os usuários em times. Isso
        permite a melhor visualização das equipes e dados de usuários. Para saber mais, confira o ‘Manual do Menu Gestão
        de Pessoas’ disponível na Trilha “Voando mais alto com a VDI” na Unibê.
      </FloraTypography>
    ),
  },
  {
    id: 'quando-alguem-do-time-sair-de-ferias-e-possivel-que-alguem-fique-responsavel-pela-base-na-vdi',
    question: 'Quando alguém do time sair de férias, é possível que alguém fique responsável pela base na VDI?',
    answer: (
      <>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Às vezes um supervisor pode cuidar de mais de um setor, ou vai cobrir as férias de algum colega. Para isso
          precisa visualizar mais de um setor na VDI, que sempre vai refletir a configuração de setores lá da VD+.
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Por exemplo: Se Fulano é responsável pelo setor A na VD+ → visualiza base A na VDI. Se Beltrano é responsável
          pelo setor B na VD+ visualiza B na VDI.
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Por exemplo: Se Fulano é responsável pelo setor A na VD+ → visualiza base A na VDI. Se Beltrano é responsável
          pelo setor B na VD+ visualiza B na VDI.
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          E caso o colega que atende o setor B saia de férias e Fulano vá cobrir o setor dele durante esse período, ele
          deverá ser corresponsável pelo setor dele na VD+, visualizando os setores A e B. Lembrando que não é possível
          compartilhar acessos na VD+. O acesso precisa ser da pessoa que está utilizando e quando não for mais
          necessária a visualização das férias é só ir na VD+ e atualizar os setores deste usuário. Ao alterar setores
          ou base de REs, essas alterações podem levar algumas horas para serem refletidas na VDI.
        </FloraTypography>
      </>
    ),
  },
  {
    id: 'onde-encontrar-ajuda-em-caso-de-duvidas-ou-problemas-com-a-vdi',
    question: 'Onde encontrar ajuda em caso de dúvidas ou problemas com a VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        O time do CSF poderá te ajudar pelo telefone <strong>(41) 3375-1515</strong> ou chat disponível na Extranet.
      </FloraTypography>
    ),
  },
  {
    id: 'perderei-meu-acesso-ao-vd-mais',
    question: 'Perderei meu acesso ao VD+?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        As funcionalidades do VD+ que estão relacionadas ao trabalho da Força de Vendas serão desligadas gradualmente à
        medida que forem implementadas na VDI. Funcionalidades de logística, faturamento, etc, continuam na VD+ de
        acordo com o permissionamento de cada usuário. Todos esses processos ocorrerão após comunicação prévia.
      </FloraTypography>
    ),
  },
  {
    id: 'o-que-acontecera-com-o-app-da-super-e-o-superplano',
    question: 'O que acontecerá com o App da Super e o Superplano?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Essas soluções serão desidratadas à medida que a VDI esteja com todas as informações e dados necessários para a
        operação. Por enquanto, ninguém perderá nenhum acesso às essas ferramentas, o desligamento destas será
        comunicado com antecedência.
      </FloraTypography>
    ),
  },
  {
    id: 'como-funciona-a-consulta-de-estoque-e-itens-similares-na-vdi',
    question: 'Como funciona a consulta de estoque e itens similares na VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Agora é possível consultar o estoque e produtos similares direto pela VDI! Em{' '}
        <strong>Ativação &gt; Atendimento</strong>, busque o Revendedor, clique para iniciar um atendimento e depois em
        <strong>Consultar similares</strong>, que estará ao lado do botão de finalizar atendimento. Para recomendar
        produtos similares, o algoritmo leva em consideração uma série de atributos como marca, categoria, linha,
        caminho olfativo, etc. Depois ele confere se temos esse produto similar em estoque e sugere na tela. Para mais
        informações, consulte o Manual de Navegação disponível na Trilha “Voando mais alto com a VDI” na Unibê.
      </FloraTypography>
    ),
  },
  {
    id: 'quais-os-recursos-mais-importantes-da-vdi-para-o-meu-trabalho',
    question: 'Quais os recursos mais importantes da VDI para o meu trabalho?',
    answer: (
      <ul className="listinha">
        <li style={{ marginBottom: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallStandard">
            <strong>Segmentação de Base:</strong> permite visualizar a base de Revendedoras de acordo com o seu setor,
            filtrar por diversos critérios como nível de inatividade, ciclo atual, status de bloqueio, etc. e realizar
            ações de ativação, como acionamentos, atendimento, etc.
          </FloraTypography>
        </li>
        <li style={{ marginBottom: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallStandard">
            <strong>Registro de acionamentos:</strong> permite registrar o envio massificado de mensagens para suas
            Revendedoras, informando sobre promoções, campanhas, eventos, cobranças, etc.
          </FloraTypography>
        </li>
        <li style={{ marginBottom: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallStandard">
            <strong>Registro de atendimentos:</strong> permite registrar as interações com suas Revendedoras, como
            contato por telefone, WhatsApp ou mensagens, identificando o motivo e o submotivo do atendimento.
          </FloraTypography>
        </li>
        <li style={{ marginBottom: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallStandard">
            <strong>Indicadores de Relacionamento:</strong> permite acompanhar o desempenho da sua equipe em relação a
            acionamentos, atendimentos e problemas de contato, além de visualizar a performance da sua equipe em relação
            a vendas e receitas.
          </FloraTypography>
        </li>
        <li style={{ marginBottom: '$2' }}>
          <FloraTypography as="p" fontSize="bodySmallStandard">
            <strong>Visualização de resultados:</strong> acompanhe de forma prática e consolidada os principais
            indicadores do seu negócio, para formular estratégias e entender pontos de ação.
          </FloraTypography>
        </li>
      </ul>
    ),
  },
  {
    id: 'qual-a-diferenca-pratica-entre-acionamento-e-atendimento',
    question: 'Qual a diferença prática entre Acionamento e Atendimento?',
    answer: (
      <>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          <strong>Acionamentos são ações realizadas para acionar uma ou mais REs</strong>. É o que chamamos de ativo.{' '}
          <strong>Já os Atendimentos são as trocas de mensagem</strong>, seja quando a RE retorna um acionamento
          anterior ou fala espontaneamente com você.{' '}
          <strong>
            Por enquanto,{' '}
            <u>problemas de contato que vieram de algum acionamento também devem ser registrados como atendimento</u>
          </strong>
          .
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          Na prática:
        </FloraTypography>
        <FloraTypography as="p" fontSize="bodySmallStandard">
          <strong>
            Você quer avisar a todas as REs que faltam R$ 200 para mudar de faixa de Fidelidade. Agora é o momento de
            registrar o <u>Acionamento:</u>
          </strong>
        </FloraTypography>
        <ul className="listinha">
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              No menu Ativação, clique em SEGMENTAÇÃO DE BASE
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Em FILTROS, vá até Fidelidade e, no campo “valor para subir de faixa” digite 200, clique em “Aplicar
              Filtros”
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              No topo da lista, selecione todas as REs e clique no botão “Copiar itens selecionados”
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Ao abrir a janela clique em REGISTRAR ACIONAMENTO. Nesse exemplo, como estamos enviando sobre o tema
              Fidelidade vamos escolher o tipo “Campanha” e registre!
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Quando você clicou para copiar, os números das REs filtradas foram copiados, agora vá até a ferramenta de
              comunicação que utiliza e envie a mensagem.
            </FloraTypography>
          </li>
        </ul>
        <FloraTypography as="p" fontSize="bodySmallShort">
          <strong>
            Você enviou mensagem no acionamento para 10 REs, onde 2 vão fazer pedido, 1 irá no ER, 1 te bloqueou e 6 não
            responderam. Veja como registrar:
          </strong>
        </FloraTypography>
        <ul className="listinha">
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              No menu Ativação, clique em ATENDIMENTO;
            </FloraTypography>
          </li>
          <li style={{ marginTop: '$2' }}>
            <FloraTypography as="p" fontSize="bodySmallShort">
              Busque pelo código e:
            </FloraTypography>
            <ul className="listinha">
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  Para as <u>REs que realizaram os pedidos</u>, após finalizar o pedido, feche o atendimento e registre
                  como Revendedor entrou em contato &gt; motivo Venda &gt; escolha o submotivo e finalize
                </FloraTypography>
              </li>
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  Para a <u>RE que vai no ER</u>, finalize o atendimento registrando como Revendedor entrou em contato
                  &gt; motivo Promessa de compra &gt; escolha o submotivo e finalize
                </FloraTypography>
              </li>
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  Para a <u>RE que te bloqueou</u> registre como Eu entrei em contato &gt; Revendedor não respondeu &gt;
                  motivo Número bloqueado
                </FloraTypography>
              </li>
              <li style={{ marginTop: '$2' }}>
                <FloraTypography as="p" fontSize="bodySmallShort">
                  <u>Para as 6 que não responderam</u> você não deve fazer nenhum tipo de registro.
                </FloraTypography>
              </li>
            </ul>
          </li>
        </ul>
      </>
    ),
  },
  {
    id: 'como-visualizar-na-vdi-em-qual-ciclo-os-revendedores-estao-durante-a-captacao-estendida',
    question: 'Como visualizar na VDI em qual ciclo os Revendedores estão durante a captação estendida?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        Quando estamos em captação estendida, uma mensagem azul aparecerá no topo da lista na{' '}
        <strong>aba ‘Segmentação de Base’</strong> e a coluna “Ciclo Atual” fica disponível. A partir dela, é possível{' '}
        <strong>filtrar REs pelo ciclo atual</strong>.
      </FloraTypography>
    ),
  },
  {
    id: 'que-tipo-de-ofertas-aparecem-na-secao-promocoes-da-vdi',
    question: 'Que tipo de ofertas aparecem na seção ‘Promoções’ da VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        As promoções disponíveis para consulta no perfil do Revendedor na VDI são as do Alerta Multi (antigo Ânfora
        Dourada), exclusivas para o cadastro daquele RE. Para as demais ofertas do ciclo, consulte os materiais do
        ciclo.
      </FloraTypography>
    ),
  },
  {
    id: 'quais-indicadores-e-possivel-acompanhar-na-vdi',
    question: 'Quais indicadores é possível acompanhar na VDI?',
    answer: (
      <FloraTypography as="p" fontSize="bodySmallStandard">
        É possível acompanhar, através do Menu Resultados, indicadores de relacionamento, atividade, perfil de base,
        receita e uma visão geral, de maneira bem completa e intuitiva.
      </FloraTypography>
    ),
  },
];
