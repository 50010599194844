import { Flex, FloraTypography, FloraButton } from '@grupoboticario/flora-react';
import { SquareArrowRightUpIcon } from '@grupoboticario/flora-react-icons';

export function UnibeBanner() {
  return (
    <Flex
      direction="row"
      align="center"
      css={{ flex: '0 0 0', borderRadius: '$medium', backgroundColor: 'rgba(102, 102, 102, 0.1)', padding: '$6' }}
    >
      <FloraTypography
        css={{
          color: 'rgba(38, 79, 236, 1)',
          fontSize: '$subtitleMobile',
          fontWeight: '600',
          lineHeight: '28px',
          margin: 0,
        }}
      >
        Complete a trilha na Unibê e conheça todas as funcionalidades da VDI
      </FloraTypography>
      <FloraButton
        as="a"
        href="https://unibe.grupoboticario.com.br/missions/p7Jyv7tjLzjTHBI0hcaj"
        target="_blank"
        has="iconRight"
        icon={<SquareArrowRightUpIcon />}
        hierarchy="primary"
        styleSemantic="neutral"
      >
        Acessar Unibê
      </FloraButton>
    </Flex>
  );
}
