/**
 * Push an event to the Google Analytics dataLayer
 * @param eventParameter string of the event to trigger
 */
export function pushEventToDataLayer({ eventParameter }: { eventParameter: string }): void {
  // Ensure dataLayer exists
  if (typeof window !== 'undefined') {
    window.dataLayer = window.dataLayer || [];

    // Push the event to dataLayer
    window.dataLayer.push({
      event: 'analytics-event',
      event_parameters: {
        event_name: 'interaction_home',
        cd_interaction_detail: eventParameter,
      },
    });
  }
}
