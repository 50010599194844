import { Flex, FloraTypography, FloraButton } from '@grupoboticario/flora-react';
import { SquareArrowRightUpIcon } from '@grupoboticario/flora-react-icons';
import { pushEventToDataLayer } from '@shared/analytics/pushEventToDataLayer';

export function UnibeBanner() {
  return (
    <Flex
      direction="row"
      align="center"
      css={{ flex: '0 0 0', borderRadius: '$medium', backgroundColor: 'rgba(102, 102, 102, 0.1)', padding: '$6' }}
    >
      <FloraTypography
        css={{
          color: 'rgba(38, 79, 236, 1)',
          fontSize: '$subtitleMobile',
          fontWeight: '600',
          lineHeight: '28px',
          margin: 0,
        }}
      >
        Acesse o treinamento Unibê para saber mais
      </FloraTypography>
      <FloraButton
        as="a"
        href="https://unibe.grupoboticario.com.br/missions/w1NkUklJXQElpXoYPNND"
        target="_blank"
        has="iconRight"
        icon={<SquareArrowRightUpIcon />}
        hierarchy="primary"
        styleSemantic="neutral"
        css={{ marginInline: '$4' }}
        onClick={() => {
          pushEventToDataLayer({
            eventParameter: 'click:acessar-unibe:trilha-lider',
          });
        }}
      >
        Trilha Líder
      </FloraButton>
      <FloraButton
        as="a"
        href="https://unibe.grupoboticario.com.br/missions/p7Jyv7tjLzjTHBI0hcaj"
        target="_blank"
        has="iconRight"
        icon={<SquareArrowRightUpIcon />}
        hierarchy="secondary"
        styleSemantic="neutral"
        onClick={() => {
          pushEventToDataLayer({
            eventParameter: 'click:acessar-unibe:trilha-assistente',
          });
        }}
      >
        Trilha Assistente
      </FloraButton>
    </Flex>
  );
}
