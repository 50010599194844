import { FloraTypography, Grid } from '@grupoboticario/flora-react';

import { ProfileContentSwitcher } from '@shared/components/ProfileContentSwitcher';
import { UserProfile } from '@shared/types/userProfiles';
import { DownloadableContent as AdminDownloadableContent } from './AdminProfile/DownloadableContent';
import { DownloadableContent as AttendantDownloadableContent } from './AttendantProfile/DownloadableContent';

type InsideVDIProps = {
  userProfiles: UserProfile[];
};

export function InsideVDI({ userProfiles }: InsideVDIProps) {
  return (
    <>
      <FloraTypography
        as="h3"
        fontWeight="bold"
        css={{ fontSize: '$subtitleMobile', lineHeight: '28px', marginTop: '$12' }}
      >
        Por dentro da VDI
      </FloraTypography>
      <Grid templateColumns="repeat(3, 1fr)" gap="1rem">
        <ProfileContentSwitcher
          userProfiles={userProfiles}
          profileToMatch={['Administrador Franquias Default', 'Coordenador Franquias Default']}
          contentForMatchedProfile={<AdminDownloadableContent />}
          contentFormNonMatchedProfile={<AttendantDownloadableContent />}
        />
      </Grid>
    </>
  );
}
